import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  align-items: center;
  background: #000000b3;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
`;

const ModalBody = styled.div`
  width: 100%;
  max-width: 520px;
  padding: 32px;
  margin: 12px;
  background: #fff;
  border-radius: 5px;
  font-size: 18px;
  line-height: 1.5;
`;

const StyledLink = styled.a`
  color: #5e0092;
  text-decoration: underline;

  &:hover,
  &:active {
    background-color: #fbf0ff;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const baseButtonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 16px 24px;
  margin: 20px 0 0 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 99px;
  outline: 0;
  transition: all 150ms ease;
  border-style: solid;
  border-width: 1px;
  color: #fff;
`;

const ButtonLink = styled.a`
  background-color: #29003e;
  border-color: #29003e;
  ${baseButtonStyle}

  &:hover {
    background: #5e0092;
    color: #fff;
  }
`;

const RedirectionModal = ({ hostname }) => {
  const AWS_URL = `https://${hostname}`;

  return (
    <Container>
      <ModalBody>
        <div>
          <h2>Viktig informasjon</h2>
          HomeService vil bytte til ny URL: <StyledLink href={AWS_URL}>{AWS_URL}</StyledLink>. Den
          gamle adressen vil bli deaktivert. Ved spørsmål, ta kontakt med Field Service Manager:
          Sara Johnsen -{' '}
          <StyledLink mailto="sara.johnsen@telia.no">sara.johnsen@telia.no</StyledLink>.
        </div>
        <ModalActions>
          <ButtonLink href={AWS_URL}>Go to new URL address</ButtonLink>
        </ModalActions>
      </ModalBody>
    </Container>
  );
};

RedirectionModal.propTypes = {
  hostname: PropTypes.string.isRequired,
};

export default RedirectionModal;
